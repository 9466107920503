<template>
    <div class="az-content pd-y-20 pd-lg-y-30 pd-xl-y-40">
        <div class="container">
            <div class="az-content-left az-content-left-components">
                <div class="component-item">
                    <button @click="hideLeftMenu" aria-label="Close" class="close d-block d-sm-none" type="button">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <label>Provenance</label>
                    <nav class="nav flex-column">
                        <router-link :to="{ name: 'provenances-home'}" class="nav-link" exact>Home</router-link>
                        <router-link :to="{ name: 'provenances-registries'}" class="nav-link">Process Registries</router-link>
                        <router-link :to="{ name: 'ongoing-process'}" class="nav-link">Ongoing Processes
                            <span class="notification notification-blue" v-if="ongoingProcessCount>0">{{ongoingProcessCount}}</span>
                        </router-link>
                        <router-link :to="{ name: 'complete-process'}" class="nav-link">Completed Processes
                            <span class="notification notification-blue" v-if="completeProcessCount>0">{{completeProcessCount}}</span>
                        </router-link>
                        <router-link :to="{ name: 'pending-process'}" class="nav-link">Awaiting Attestation
                            <span class="notification notification-red" v-if="pendingStepCount>0">{{pendingStepCount}}</span>
                        </router-link>
                    </nav>
                </div><!-- component-item -->

            </div>
                <router-view></router-view>
        </div>

    </div>
</template>

<script>
    import EventBus from "@/event-bus";
    import {mapGetters} from "vuex";

    export default {
        name: "ProvenanceLayout",
        computed: {
            ...mapGetters(["currentUser"])
        },
        data() {
            return {
                availableProcessCount: 0,
                ongoingProcessCount: 0,
                completeProcessCount: 0,
                pendingStepCount: 0
            };
        },
        mounted() {
            this.init();
            EventBus.$on('updateProcessCount', () => {
                this.getAvailableProcessCount();
            });
            EventBus.$on('updateProcessCount', () => {
                this.getOngoingProcessCount();
            });
            EventBus.$on('updatePendingStepCount', () => {
                this.getPendingStepCount();
            });
            EventBus.$on('updateCompleteCount', () => {
                this.getCompleteProcessCount();
            });
        },
        methods: {
            hideLeftMenu() {
                $('body').removeClass('az-content-left-show');
            },
            async init() {
                await Promise.all([
                    // this.getAvailableProcessCount(),
                    this.getOngoingProcessCount(),
                    this.getCompleteProcessCount(),
                    this.getPendingStepCount(),
                ])
            },
            async getAvailableProcessCount() {
                try {
                    let {data} = await this.$provenance.get(`provenance/registries/available_definitions?page=0&per_page=100`);
                    this.availableProcessCount = data ? data.total : 0;
                } catch (e) {
                    if (!this.currentUser.did) {
                        this.$toastr.e("Please select your 'acting as' DID first", 'Error');
                        await this.$router.push({name: 'myorg'});
                    } else {
                        this.$toastr.e("Error retrieving available process count", 'Error');
                    }
                }
            },
            async getOngoingProcessCount() {
                try {
                    let {data} = await this.$provenance.get(`provenance/registries/processes_in_progress?page=0&per_page=100`);
                    this.ongoingProcessCount = data ? data.total : 0;
                } catch (e) {

                }
            },
            async getPendingStepCount() {
                try {
                    let {data} = await this.$provenance.get(`provenance/registries/processes_pending?page=0&per_page=100`);
                    this.pendingStepCount = data ? data.total : 0;
                } catch (e) {

                }
            },
            async getCompleteProcessCount() {
                try {
                    let {data} = await this.$provenance.get(`provenance/registries/processes_completed?page=0&per_page=100`);
                    this.completeProcessCount = data ? data.total : 0;
                } catch (e) {

                }
            },
        }
    }
</script>

<style scoped>
</style>